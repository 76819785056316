@tailwind base;
@tailwind components;
@tailwind utilities;

.text-shadow-1 {
  text-shadow: 0 1px 3px #00000070;
}
.view-contract {
  color: #000;
  font-size: 16px !important;
  height: auto !important;
  padding: 12px 24px;
  border-radius: 10px;
  padding-bottom: 13px;
}

.btn-size-2 {
  font-size: 12px !important;
  color: #000;
  padding: 5px 20px !important;
  border-radius: 10px;
  height: auto !important;
}
.header .btn-gray {
  height: 47px;
}
.footer .btn-gray {
  width: 154px;
}
.sidebar .btn-primary,
.sidebar .btn-gray {
  width: 100%;
  font-size: 16px;
  height: 55px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.stats-content .sub {
  font-size: 14px !important;
  margin-left: 3px;
}

.tabs button:not(:last-child) {
  margin-right: 20px;
}
.tabs button {
  margin-bottom: 15px;
  height: 52px;
  padding: 10px 24px;
  background-color: #353535;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  transition: 0.3s;
}
.tabs button.active {
  background-color: #52b86e;
}
.mobile-bar:hover svg {
  stroke: #f6bc18;
}
.yScroll {
  overflow-y: scroll;
  scrollbar-width: thin;
}
.pay .logo img {
  width: 126px;
}
.processing-fee {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
}
.quick-btn button {
  min-width: 230px;
  text-align: center;
}
/* loader css start */
.loader {
  width: 36px;
  height: 36px;
  border: 4px solid #52b86e;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader css end */

@media screen and (max-width: 640px) {
  .tabs button {
    margin-bottom: 0;
    height: 48px;
    padding: 7px 24px;
    font-size: 15px;
  }
  .tabs button:not(:last-child) {
    margin-right: 0;
  }
  .table-custom-scroller {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 400px) {
  .pay .chains button:not(:last-child) {
    margin-right: 5px;
  }
}

/* Table style */
.table-custom {
  background-color: #252525;
  color: #cdcdcd;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  overflow: auto;
}
.table-custom th {
  background-color: #52B86E;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  padding: 10px 15px;
  line-height: 17px;
}
.table-custom table th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.table-custom table th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table-custom td {
  padding: 8px 13px;
  font-size: 13px;
}
.table-custom td:first-child,
.table-custom th:first-child {
  text-align: left;
}
.table-custom td:last-child,
.table-custom th:last-child {
  text-align: right;
}
.table-custom tr:nth-child(even) {
  background-color: #343434;
}
.table-custom tr:nth-child(even) td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.table-custom tr:nth-child(even) td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media screen and (max-width: 767px) {
  .table-custom-body {
    overflow-x: scroll;
  }
}

.web3modal {
  background-color: #000;
}